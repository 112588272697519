
<template>
<div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
   <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
      <div slot="no-body" class="full-page-bg-color">

         <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
            <img src="@/assets/images/logo/logo-204x204.png" alt="login" class="responsive mx-auto p-10">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
            <div class="p-8 login-tabs-container">

               <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Aktivasi</h4>
                  <p>Masukkan kode aktivasi yang terdapat pada email anda.</p>
               </div>

               <div>

                  <vs-input
                     icon-no-border
                     icon="icon icon-mail"
                     icon-pack="feather"
                     v-validate="'required|email'"
                     data-vv-validate-on="blur"
                     name="Email"
                     type="email"
                     label-placeholder="Email"
                     placeholder="Email"
                     v-model="email"
                     class="w-full mt-6" />
                  <span class="text-danger text-sm">{{ errors.first('Email') }}</span>

                  <vs-input
                     data-vv-validate-on="blur"
                     v-validate="'required|digits:8'"
                     name="aktivasi"
                     icon-no-border
                     icon="icon icon-command"
                     icon-pack="feather"
                     label-placeholder="Kode Aktivasi"
                     v-model="aktivasi"
                     class="w-full"/>
                  <span class="text-danger text-sm">{{ errors.first('aktivasi') }}</span>

                  <div class="flex flex-wrap justify-between mt-8">
                  <vs-button type="border" color="primary" @click="toLogin" class="">Login</vs-button>
                  <vs-button :disabled="!validateForm" class="float-right" @click="submitAktivasi">Aktivasi</vs-button>
                  </div>
                  <div class="flex flex-wrap justify-between mt-4">
                  </div>
               </div>

            </div>
            </div>
         </div>
      </div>
      </vx-card>
   </div>

</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default{

data() {
   return {
      aktivasi: '',
      email: ''
   }
},

mounted() {
   //this.$router.push("/");
},

computed: {
   ...mapGetters({
      user  : 'auth/user',
      menu  : 'akses/menu',
      prevUrl : 'prevUrl',
   }),
   validateForm() {
      return !this.errors.any() && this.aktivasi !== '' && this.email !== ''
   }
},
methods: {
   ...mapActions({
      setAuth   : 'auth/set'
   }),
   submitAktivasi() {
      if (!this.errors.any()) {
      this.$vs.loading()
      let formData = {
         'email' : this.email,
         'aktivasi' : this.aktivasi
      }
      this.axios.post('/aktivasi', formData)
      .then((response) => {
         this.$vs.loading.close()
         this.$vs.notify({
            title : 'Aktivasi Berhasil',
            color : 'success',
            text  : response.data.message,
         })
         this.$router.push('/login')
         this.close()
      })
      .catch((error) => {
         this.$vs.loading.close()
         let { data } = error.response
         this.$vs.notify({
            title: 'Error',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
         })
      })
      
      }
   },
   toLogin () {
      this.$router.push('/login').catch(() => {})
   }
},
}
</script>
